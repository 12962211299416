import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AdminNavbar.css";
import "../Boot/Boot.css";
import { auth, storage, updateProfile } from "../../firebase";
import logo from "../Assets/images/logo.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SearchIcon from "@mui/icons-material/Search";
import { useStateValue } from "../../StateProvider";
import { Avatar } from "@mui/material";
import AdminHeader from "./AdminHeader";
import EditIcon from "@mui/icons-material/Edit";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function AdminNavbar() {
  const [{ cart, user }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleAuthentication = () => {
    if (user) {
      auth.signOut().then(() => {
        window.location.href = "/";
      });
    }
  };

  const handleProfilePictureClick = () => {
    setPopupVisible(!popupVisible);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewPhoto(file);

    if (file) {
      const objectURL = URL.createObjectURL(file);
      setPreviewURL(objectURL);
    } else {
      setPreviewURL(null);
    }
  };

  const handleUpload = async () => {
    if (newPhoto && user) {
      setUploading(true);
      try {
        const photoRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(photoRef, newPhoto);
        const url = await getDownloadURL(photoRef);
        await updateProfile(user, { photoURL: url });
        dispatch({ type: "SET_USER", user: { ...user, photoURL: url } });
        setPopupVisible(false);
        setPreviewURL(null);
      } catch (error) {
        console.error("Error updating profile picture:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch({ type: "SET_USER", user: authUser });
      } else {
        dispatch({ type: "SET_USER", user: null });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [user, dispatch]);

  return (
    <div className="sticky-top">
      <div className="header navbar navbar-expand-xl navbar-dark">
        <div className="container pb-2 float-left">
          <a href="/">
            <img src={logo} className="header_logo" alt="TrendBay-logo" />
          </a>
          <div className="float-right d-flex">
            <div className="header_option nav-item shop-nav pt-head">
              <span className="notification-icon">
                <NotificationsIcon />
              </span>
            </div>
            <div className="header_option nav-item shop-nav pt-head">
              <span className="power-button" onClick={handleAuthentication}>
                <PowerSettingsNewIcon />
              </span>
            </div>
            <div className="header_option nav-item shop-nav pt-1">
              <Avatar
                alt="TrendBay DS"
                src={user?.photoURL}
                sx={{ width: 40, height: 40 }}
                onClick={handleProfilePictureClick}
              />
              {popupVisible && (
                <div className="profile-popup">
                  <p></p>
                  {previewURL && (
                    <div className="profile-popup-preview float-right">
                      <img
                        src={previewURL}
                        alt="TrendBay"
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                    </div>
                  )}
                  <input type="file" onChange={handleFileChange} />
                  <button
                    onClick={handleUpload}
                    disabled={!newPhoto || uploading}
                  >
                    {uploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AdminHeader />
    </div>
  );
}
