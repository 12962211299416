import React, { useEffect, useRef, useState } from "react";
import { Paper, Typography } from "@mui/material";
import EChartsReactCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart, LineSeriesOption } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { collection, getDocs, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

echarts.use([
  LineChart,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  CanvasRenderer,
]);

const VisitorsChart = () => {
  const chartRef = useRef(null);
  const [data, setData] = useState({
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    visitors: Array(12).fill(0),
  });

  useEffect(() => {
    const fetchVisitorData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "visitors"));
        const visitorsCount = Array(12).fill(0);

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const monthIndex = new Date(data.timestamp.seconds * 1000).getMonth();
          visitorsCount[monthIndex] += 1;
        });

        setData((prevData) => ({
          ...prevData,
          visitors: visitorsCount,
        }));
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      }
    };

    fetchVisitorData();

    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartRef]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "#FFA726",
        },
        label: {
          backgroundColor: "#FFA726",
        },
      },
    },
    legend: {
      show: false,
      data: ["Visitors"],
    },
    grid: {
      top: "5%",
      right: "3.25%",
      bottom: "4%",
      left: "1.25%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: data.months,
      axisLine: {
        show: true,
        lineStyle: {
          color: "#1E90FF",
        },
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLabel: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "#000",
        margin: 15,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      max: Math.max(...data.visitors) + 10,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        padding: [0, 5, 0, 0],
        fontSize: "12px",
        fontWeight: "bold",
        color: "#000",
      },
    },
    series: [
      {
        name: "Visitors",
        type: "line",
        stack: "Total",
        smooth: false,
        color: "#1E90FF",
        lineStyle: {
          width: 2,
          color: "#1E90FF",
        },
        showSymbol: false,
        areaStyle: {
          opacity: 1,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 1, color: "#B0E0E6" },
            { offset: 0, color: "#1E90FF" },
          ]),
        },
        emphasis: {
          focus: "series",
        },
        data: data.visitors,
      },
    ],
  };

  return (
    <EChartsReactCore
        ref={chartRef}
        echarts={echarts}
        option={option}
        style={{ height: "300px", width: "100%" }}
      />
  );
};

export default VisitorsChart;
