import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AdminHeader.css";
import "../Boot/Boot.css";

function AdminHeader() {
  const location = useLocation();
  const [activeNav, setActiveNav] = useState(location.pathname);

  const admin_nav = [
    {
      display: "Dashboard",
      path: "/",
    },
    {
      display: "All Products",
      path: "/d/all-products",
    },
    {
      display: "Add Products",
      path: "/d/add-product",
    },
    {
      display: "Orders",
      path: "/d/orders",
    },
    {
      display: "Live Chat",
      path: "/d/live-chat",
    },
  ];

  const handleNavClick = (path) => {
    setActiveNav(path);
  };

  useEffect(() => {
    setActiveNav(location.pathname);
  }, [location]);

  return (
    <div className="below_navbar d-flex">
      {admin_nav.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={activeNav === item.path ? "active" : ""}
          onClick={() => handleNavClick(item.path)}
        >
          {item.display}
        </Link>
      ))}
    </div>
  );
}

export default AdminHeader;
