import React, { useEffect } from "react";
import { PrettyChatWindow } from "react-chat-engine-pretty";
import "./AdminChat.css";

function AdminChat() {
  useEffect(() => {
    document.body.classList.add("admin-chat-bg");

    return () => {
      document.body.classList.remove("admin-chat-bg");
    };
  }, []);

  return (
    <div className="custom-chat-window">
      <PrettyChatWindow
        projectId={process.env.REACT_APP_CE_PROJECT_ID}
        username="Support Chat"
        secret="@Captain007"
      />
    </div>
  );
}

export default AdminChat;
