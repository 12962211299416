import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddProduct.css";
import noImage from "../../Components/Assets/images/no-image.jpg";
import { useTitle } from "../../hooks/useTitle";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { Checkbox } from "@mui/material";
import AdminFooter from "../../Components/AdminFooter/AdminFooter";

const CATEGORY_CODES = {
  "fresh-farm-produce": "FFP",
  "grains-and-staples": "GNS",
  "poultry-and-meat": "PLM",
  catfish: "CAT",
  "dairy-and-eggs": "DAE",
  beverages: "BEV",
  "snacks-and-confectionery": "SNC",
  "canned-and-packaged-foods": "CPF",
  "frozen-foods": "FRF",
  "condiments-and-sauces": "CNS",
};

const COMPANY_CODE = "TBDS";

const generateSlug = (product_title) => {
  return product_title
    .toString()
    .toLowerCase()
    .replace(/[^\w\s]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+$/g, ""); // Remove trailing hyphens
};

export default function AddProduct() {
  useTitle("Add Product - TrendBay Daily Solutions");
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    product_title: "",
    product_description: "",
    product_price: "",
    product_sku: "",
    category_name: "",
    product_image: "",
    available: true,
    featured: false,
  });

  const [saving, setSaving] = useState(false);

  const formatPrice = (price) => {
    const number = parseFloat(price.replace(/,/g, ""));
    if (isNaN(number)) return "";

    return number.toLocaleString("en-NG", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleInputChange = async (e) => {
    const { title, name, value, type, checked } = e.target;

    if (title === "category_name") {
      const sku = value ? await generateSKU(value) : "";
      setProduct({ ...product, [title]: value, product_sku: sku });
    } else if (title === "product_price") {
      setProduct({
        ...product,
        product_price: formatPrice(value),
      });
    } else if (type === "checkbox") {
      setProduct({ ...product, [name]: checked });
    } else {
      setProduct({ ...product, [title]: value });
    }
  };

  const validateFields = () => {
    return (
      product.product_title &&
      product.product_description &&
      product.product_price &&
      product.product_sku &&
      product.category_name &&
      product.product_image
    );
  };

  const notifyAdmin = () => {
    toast.success("Product Saved", {
      autoClose: 3000,
    });
  };

  const generateSKU = async (category) => {
    const categoryCode = CATEGORY_CODES[category];
    if (!categoryCode) return "";

    let sku;
    let exists;

    do {
      const randomDigits = Math.floor(100 + Math.random() * 900);
      sku = `${categoryCode}${randomDigits}${COMPANY_CODE}`;

      const q = query(
        collection(db, "products"),
        where("product_sku", "==", sku)
      );
      const querySnapshot = await getDocs(q);
      exists = !querySnapshot.empty;
    } while (exists);

    return sku;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      alert("Please fill in all required fields and upload an image.");
      return;
    }

    setSaving(true);

    try {
      let imageUrl = "";
      if (product.product_image) {
        const storage = getStorage();
        const imageRef = ref(storage, `images/${product.product_image.name}`);
        await uploadBytes(imageRef, product.product_image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const slug = generateSlug(product.product_title);

      const docRef = await addDoc(collection(db, "products"), {
        product_title: product.product_title,
        product_description: product.product_description,
        product_price: parseFloat(product.product_price.replace(/,/g, "")),
        product_sku: product.product_sku,
        category_name: product.category_name,
        product_image: imageUrl,
        available: product.available,
        featured: product.featured,
        slug: slug,
      });

      await updateDoc(doc(db, "products", docRef.id), {
        product_id: docRef.id,
      });

      notifyAdmin();
      navigate("/d/all-products");

      setProduct({
        product_title: "",
        product_description: "",
        product_price: "",
        product_sku: "",
        category_name: "",
        product_image: "",
        available: true,
        featured: false,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setSaving(false);
    }
  };

  const handleDiscard = () => {
    navigate("/d/all-products");
  };

  const handleClear = () => {
    setProduct({
      product_title: "",
      product_description: "",
      product_price: "",
      product_sku: "",
      category_name: "",
      product_image: "",
      available: true,
      featured: false,
    });
    setImagePreview(noImage);
  };

  const [imagePreview, setImagePreview] = useState(noImage);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProduct({ ...product, product_image: file });

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(noImage);
    }
  };

  return (
    <div>
      <div class="row container add-products">
        <div class="col-sm-8">
          <div className="add-product-information">
            <label className="float-right">
              Available
              <Checkbox
                name="available"
                checked={product.available}
                onChange={handleInputChange}
              />
            </label>
            <label className="float-right">
              Featured
              <Checkbox
                name="featured"
                checked={product.featured}
                onChange={handleInputChange}
              />
            </label>
            <h4 className="mb-2">Product Information</h4>
            <label>
              Title
              <input
                type="text"
                title="product_title"
                value={product.product_title}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Description
              <textarea
                title="product_description"
                value={product.product_description}
                onChange={handleInputChange}
                rows="15"
                required
              />
            </label>
          </div>
        </div>
        <div class="col-sm-4">
          <div className="sided-container">
            <label>
              Price
              <input
                type="text"
                className="in_Price"
                title="product_price"
                value={product.product_price}
                onChange={handleInputChange}
                placeholder="0.00"
                required
              />
              <span className="currency float-right">NGN</span>
            </label>
            <label>
              SKU
              <input
                type="text"
                title="product_sku"
                value={product.product_sku}
                onChange={handleInputChange}
                required
                disabled
              />
            </label>
            <label>
              Category
              <select
                title="category_name"
                value={product.category_name}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a category</option>
                <option value="fresh-farm-produce">Fresh Farm Produce</option>
                <option value="grains-and-staples">Grains and Staples</option>
                <option value="poultry-and-meat">Poultry and Meat</option>
                <option value="catfish">Catfish</option>
                <option value="dairy-and-eggs">Dairy and Eggs</option>
                <option value="beverages">Beverages</option>
                <option value="snacks-and-confectionery">
                  Snacks and Confectionery
                </option>
                <option value="canned-and-packaged-foods">
                  Canned and Packaged Foods
                </option>
                <option value="frozen-foods">Frozen Foods</option>
                <option value="condiments-and-sauces">
                  Condiments and Sauces
                </option>
              </select>
            </label>
            {imagePreview && (
              <img className="image-preview" src={imagePreview} alt="Preview" />
            )}
            <label>
              Product Image
              <input
                type="file"
                title="product_image"
                accept="image/*"
                onChange={handleFileChange}
                required
              />
            </label>
          </div>
        </div>
      </div>
      <div className="floating-actions mt-4 container justify-content-center">
        <button onClick={handleClear}>Clear</button>
        <button onClick={handleDiscard}>Discard</button>
        <button onClick={handleSave} disabled={saving}>
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}
