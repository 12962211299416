import React, { useState, useEffect } from "react";
import { useTitle } from "../../hooks/useTitle";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SellIcon from "@mui/icons-material/Sell";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CurrencyFormat from "react-currency-format";
import VisitorsChart from "./VisitorsChart";
import "./Dashboard.css";
import "../../Components/Boot/Boot.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function Dashboard() {
  useTitle("Dashboard - TrendBay Daily Solutions");

  const [orderTotal, setOrderTotal] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [productsSold, setProductsSold] = useState(0);
  const [newCustomers, setNewCustomers] = useState(0);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salesSnapshot = await getDocs(collection(db, "orders"));
        const orders = salesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let totalSales = 0;
        let orderCount = 0;
        let productsSoldCount = 0;

        orders.forEach((order) => {
          totalSales += parseFloat(order.orderTotal) || 0;
          orderCount += 1;
          productsSoldCount += (order.cart || []).reduce(
            (sum, item) => sum + (item.quantity || 0),
            0
          );
        });

        setOrderTotal(totalSales);
        setTotalOrders(orderCount);
        setProductsSold(productsSoldCount);

        const customersSnapshot = await getDocs(collection(db, "customers"));
        const currentDate = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(currentDate.getMonth() - 1);
        const newCustomersCount = customersSnapshot.docs.filter((doc) => {
          const customerDate = new Date(doc.data().createdAt.seconds * 1000);
          return customerDate >= oneMonthAgo;
        }).length;

        setNewCustomers(newCustomersCount);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const users = [];
        const customersSnapshot = await getDocs(collection(db, "customers"));
        customersSnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        setCustomers(users);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchCustomerData();
  }, []);

  return (
    <div className="dboard">
      <div className="container dash__card mt-4">
        <h5>Sales Summary</h5>
        <div className="short__card mt-3">
          <div className="short__card_v short__card_ts card">
            <span className="ad_ts mb-3 mt-0">
              <RequestPageIcon />
            </span>
            <span className="pb-2 ts_price">
              <strong>
                <CurrencyFormat
                  value={orderTotal}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                />
              </strong>
            </span>
            <span className="ts_span pb-2">Total Sales</span>
          </div>
          <div className="short__card_v short__card_to card">
            <span className="ad_to mb-3 mt-0">
              <AssignmentTurnedInIcon />
            </span>
            <span className="pb-2 ts_price">
              <strong>{totalOrders}</strong>
            </span>
            <span className="ts_span pb-2">Total Orders</span>
          </div>
          <div className="short__card_v short__card_ps card">
            <span className="ad_ps mb-3 mt-0">
              <SellIcon />
            </span>
            <span className="pb-2 ts_price">
              <strong>{productsSold}</strong>
            </span>
            <span className="ts_span pb-2">Products Sold</span>
          </div>
          <div className="short__card_v short__card_nc card">
            <span className="ad_nc mb-3 mt-0">
              <PersonAddAlt1Icon />
            </span>
            <span className="pb-2 ts_price">
              <strong>{newCustomers}</strong>
            </span>
            <span className="ts_span pb-2">New Customers</span>
          </div>
        </div>
      </div>
      <div className="container dash__card mt-4">
        <h5>Visitor Insights</h5>
        <VisitorsChart />
      </div>
      <div className="container dash__card mt-4 mb-5 table__list">
        <h5>Top Products</h5>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="thead-light">
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer, index) => (
                <TableRow key={customer.email}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{customer.firstName}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>
                    {new Date(
                      customer.createdAt.seconds * 1000
                    ).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="container dash__card mt-4 mb-5 table__list">
        <h5>Customers</h5>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="thead-light">
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer, index) => (
                <TableRow key={customer.email}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{customer.firstName}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>
                    {new Date(
                      customer.createdAt.seconds * 1000
                    ).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
