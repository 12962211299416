import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import "./AdminOrders.css";
import { useTitle } from "../../hooks/useTitle";

export default function AdminOrders() {
  useTitle("Orders - TrendBay Daily Solutions");
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(ordersList);
    };
    fetchOrders();
  }, []);

  return (
    <div className="order-list container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="thead-light">
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Fulfillment Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow key={order.id}>
                <TableCell>
                  <Link to={`/d/order-details/${order.id}`}>
                    #{order.orderNumber || "N/A"}
                  </Link>
                </TableCell>
                <TableCell>
                  {new Date(order.createdAt.seconds * 1000).toLocaleString()}
                </TableCell>
                <TableCell>{order.shippingInfo.fullName}</TableCell>
                <TableCell>
                  <Chip
                    label={order.paymentStatus}
                    color={
                      order.paymentStatus === "Paid" ? "success" : "warning"
                    }
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={order.fulfillmentStatus}
                    color={
                      order.fulfillmentStatus === "Fulfilled"
                        ? "success"
                        : "warning"
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    sx={{
                      backgroundColor: "#01d26a",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#028644",
                      },
                    }}
                    onClick={() => navigate(`/d/order-details/${order.id}`)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
