import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import "./AdminOrDet.css";
import LoadingScreen from "../../LoadingScreen";

const formatPrice = (price) => {
  if (isNaN(price)) return "Invalid Price";
  return parseFloat(price).toLocaleString("en-NG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export default function AdminOrDet() {
  const [order, setOrder] = useState(null);
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrder = async () => {
      const docRef = doc(db, "orders", orderId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setOrder(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };
    fetchOrder();
  }, [orderId]);

  if (!order) return <LoadingScreen />;

  const discountRate = 0.075;
  const discountAmount = order.orderTotal * discountRate;
  const subtotalWithDiscount = order.orderTotal - discountAmount;

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString();
  };

  return (
    <div>
      <div className="order-details container">
        <h4 className="text-center font-weight-bolder">Order Details</h4>
        <br />
        <div className="order-header d-flex justify-content-between align-items-center">
          <div>
            <h5>Order #{order.orderNumber || "N/A"}</h5>
            <span className="">{order.paymentStatus}</span>
            <span className="">{order.fulfillmentStatus}</span>
            <span>
              {new Date(order.createdAt.seconds * 1000).toLocaleString()}
            </span>
          </div>
        </div>
        <hr />
        <div className="order-items mb-4">
          {order.cart.map((item, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div className="d-flex align-items-center">
                <img
                  src={item.image}
                  alt={item.title}
                  className="product-image mr-3"
                />
                <div>
                  <h5 className="mb-0">{item.title}</h5>
                  <p className="mb-0">{item.description}</p>
                </div>
              </div>
              <div className="text-right">
                <p className="mb-0">₦{formatPrice(item.price)}</p>
                <p className="mb-0">Qty: {item.quantity}</p>
              </div>
            </div>
          ))}
          <div className="float-right">
            <h5 className="text-right">Order Summary</h5>
            <p className="text-right">
              Subtotal(w/D): ₦{formatPrice(subtotalWithDiscount)}
            </p>
            <p className="text-right">Tax: 7.5%</p>
            <p className="text-right">
              Total: ₦{formatPrice(order.orderTotal)}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-light">
        <div className="order-detailed container">
          <h4 className="text-center font-weight-bolder mb-h4">
            Customer Information
          </h4>
          <p className="text-center">
            Full Name: {order.shippingInfo.fullName}
          </p>
          <p className="text-center">Email: {order.shippingInfo.email}</p>
          <p className="text-center mb-5">
            Phone Number: {order.shippingInfo.phoneNumber}
          </p>
          <div className="row mb-4 conInfo">
            <div className="col-md-6 float-left">
              <h5>Shipping Address</h5>
              <p>{order.shippingInfo.address}</p>
              <p>
                {order.shippingInfo.city}, {order.shippingInfo.state}
              </p>
              <p>{order.shippingInfo.country}</p>
            </div>
            <div className="col-md-6 float-right">
              <h5>Billing Address</h5>
              <p>{order.billingInfo.fullName}</p>
              <p>{order.billingInfo.address}</p>
              <p>
                {order.billingInfo.city}, {order.billingInfo.state}
              </p>
              <p>{order.billingInfo.country}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
