import React from 'react';
import "../Boot/Boot.css"
import "./AdminFooter.css"

export default function AdminFooter() {
  return (
    <footer>
      <div className="bottom_footer">
        <div className="container">
          <div className="text-center">
            <div className="copyright">
              <p className="mt-1 mb-2 f_400">
                ©{" "}
                <strong>
                  <a
                    className="text-success"
                    href="https://trendbayds.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TrendBay Daily Solutions LTD
                  </a>{" "}
                </strong>
                2024 All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
