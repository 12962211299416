import React, { useState } from "react";
import "./Login.css";
import "../../Components/Boot/Boot.css";
import LoginLogo from "../../Components/Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useTitle } from "../../hooks/useTitle";

function Login() {
  useTitle("Admin Dashboard");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === "admin") {
          navigate("/");
        } else {
          setError("ACCESS DENIED!");
        }
      } else {
        console.error("ADMIN NOT FOUND IN DATABASE");
        setError("ACCESS DENIED!");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("ACCESS DENIED!");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  return (
    <div className="login">
      <a href="/">
        <img className="login__logo" src={LoginLogo} alt="TrendBay" />
      </a>

      <div className="login_container">
        <h2 className="text-center">Access The Admin Dashboard</h2>
        <form className="form_login" onSubmit={handleLogin}>
          <h5>Email</h5>
          <input
            type="text"
            value={email}
            onChange={handleEmailChange}
            disabled={loading}
          />

          <h5>Password</h5>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            disabled={loading}
          />

          {error && <div className="error">{error}</div>}

          <button
            type="submit"
            className="Login_signInButton"
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign In"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
