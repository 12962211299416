import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from "./Pages/auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import NotFound from "./Pages/NotFound/NotFound";
import AddProduct from "./Pages/Products/AddProduct";
import EditProduct from "./Pages/Products/EditProduct";
import AllProduct from "./Pages/Products/AllProduct";
import AdminOrders from "./Pages/AdminOrders/AdminOrders";
import AdminNavbar from "./Components/AdminNavbar/AdminNavbar";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import AdminOrDet from "./Pages/AdminOrders/AdminOrDet";
import LoadingScreen from "./LoadingScreen";
import ScrollToTop from "./ScrollToTop";
import AdminFooter from "./Components/AdminFooter/AdminFooter";
import AdminChat from "./Pages/AdminChat/AdminChat";

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthPage = location.pathname === "/login";
  const isAuthOrChatPage = location.pathname === "/login" || location.pathname === "/d/live-chat";

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        setUserRole(userDoc.exists() ? userDoc.data().role : "user");
      } else {
        setUserRole(null);
      }
      setLoading(false);
      if (!user && !isAuthPage) {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate, isAuthPage]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="app">
      <ScrollToTop />
      <ToastContainer />
      {!isAuthPage && <AdminNavbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        {userRole === "admin" && (
          <>
            <Route path="/d/order-details/:orderId" element={<AdminOrDet />} />
            <Route path="/d/live-chat" element={<AdminChat />} />
            <Route path="/d/orders" element={<AdminOrders />} />
            <Route path="/d/all-products" element={<AllProduct />} />
            <Route path="/d/edit-product/:id" element={<EditProduct />} />
            <Route path="/d/add-product" element={<AddProduct />} />
            <Route path="/" element={<Dashboard />} />
          </>
        )}
      </Routes>
      {!isAuthOrChatPage && <AdminFooter />}
    </div>
  );
}

export default function AdminApp() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}
