import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Checkbox,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  Paper,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "./AllProduct.css";
import { useTitle } from "../../hooks/useTitle";
import SearchIcon from "@mui/icons-material/Search";

export default function AllProduct() {
  useTitle("All Products - TrendBay Daily Solutions");
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
      const totalPages = Math.ceil(productsList.length / 10);
      setTotalPages(totalPages);
      setDisplayedProducts(productsList.slice(0, 10));
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const filteredProducts = products.filter(
      (product) =>
        product.product_title.toLowerCase().includes(query.toLowerCase()) ||
        product.category_name.toLowerCase().includes(query.toLowerCase())
    );
    const totalPages = Math.ceil(filteredProducts.length / 10);
    setTotalPages(totalPages);
    setCurrentPage(1); // Reset to the first page
    setDisplayedProducts(filteredProducts.slice(0, 10));
  }, [query, products]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      const startIndex = nextPage * 10 - 10;
      const newDisplayedProducts = products.slice(startIndex, nextPage * 10);
      setDisplayedProducts(newDisplayedProducts);
      setCurrentPage(nextPage);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      const startIndex = previousPage * 10 - 10;
      const newDisplayedProducts = products.slice(
        startIndex,
        previousPage * 10
      );
      setDisplayedProducts(newDisplayedProducts);
      setCurrentPage(previousPage);
    }
  };

  const handleEdit = (id) => {
    navigate(`/d/edit-product/${id}`);
  };

  const handleDelete = (id) => {
    setProductToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (productToDelete) {
      try {
        await deleteDoc(doc(db, "products", productToDelete));
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productToDelete)
        );
        setDisplayedProducts((prevDisplayed) =>
          prevDisplayed.filter((product) => product.id !== productToDelete)
        );
        toast.success("Product Deleted", {
          autoClose: 3000,
        });
      } catch (error) {
        console.error("Error deleting product: ", error);
        toast.error("Failed to delete product", {
          autoClose: 3000,
        });
      } finally {
        setDeleteDialogOpen(false);
        setProductToDelete(null);
      }
    }
  };

  const handleAvailabilityChange = async (id, available) => {
    const productRef = doc(db, "products", id);
    await updateDoc(productRef, { available });
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, available } : product
      )
    );
    setDisplayedProducts((prevDisplayed) =>
      prevDisplayed.map((product) =>
        product.id === id ? { ...product, available } : product
      )
    );
  };

  const handleFeatureChange = async (id, featured) => {
    const productRef = doc(db, "products", id);
    try {
      await updateDoc(productRef, { featured });
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? { ...product, featured } : product
        )
      );
      setDisplayedProducts((prevDisplayed) =>
        prevDisplayed.map((product) =>
          product.id === id ? { ...product, featured } : product
        )
      );
    } catch (error) {
      console.error("Error updating product feature status: ", error);
      toast.error("Failed to update product feature status", {
        autoClose: 3000,
      });
    }
  };

  const formatCategoryName = (slug) => {
    return slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatPrice = (price) => {
    return parseFloat(price)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className="container all__products">
      <div className="all__search pt-3 pr-3 pl-3 search_func">
        <input
          id="searchInput"
          className="header__searchInput"
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleInputChange}
        />
        <div className="header__searchIcon">
          <SearchIcon fontSize="medium" />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="thead-light">
            <TableRow className="">
              <TableCell>#</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Price(₦)</TableCell>
              <TableCell>Featured</TableCell>
              <TableCell>Available</TableCell>
              <TableCell>Out of Stock</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedProducts.map((product, index) => (
              <TableRow key={product.id}>
                <TableCell>{index + 1 + (currentPage - 1) * 10}</TableCell>
                <TableCell>
                  <img
                    src={product.product_image}
                    alt={product.product_title}
                    className="product-image"
                  />
                </TableCell>
                <TableCell>{product.product_title}</TableCell>
                <TableCell>
                  {formatCategoryName(product.category_name)}
                </TableCell>
                <TableCell>{product.product_sku}</TableCell>
                <TableCell>{formatPrice(product.product_price)}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={product.featured}
                    onChange={() =>
                      handleFeatureChange(product.id, !product.featured)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={product.available}
                    onChange={() => handleAvailabilityChange(product.id, true)}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={!product.available}
                    onChange={() => handleAvailabilityChange(product.id, false)}
                  />
                </TableCell>
                <TableCell className="icon__cell">
                  <IconButton
                    className="mr-2"
                    onClick={() => handleEdit(product.id)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(product.id)}
                    style={{ color: "red" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="pagination-controls">
        {currentPage > 1 && <Button onClick={handlePrevious}>Previous</Button>}
        {currentPage < totalPages && <Button onClick={handleNext}>Next</Button>}
      </div>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
